<template>
  <div>
    <!-- <input
      type="file"
      accept="image/*"
      @change="handleFileSelect"
      multiple
    >
    <v-row
      v-for="(item) in items"
      :key="item.uid"
    >
      <v-col cols="6">
        <Preview
          :file="item.file"
          :uid="item.uid"
          @click-delete="clickDelete"
        ></Preview>
      </v-col>

    </v-row> -->

    <!-- <SimpleGallery
      class="mt-1"
      :items="imageList"
      @click-delete="clickDeleteImage"
    ></SimpleGallery> -->

  </div>
</template>
  
  <script>
import { getUID } from "../js/custom";
import Preview from "./image-preview-item.vue";
import SimpleGallery from "./SimpleGallery.vue";

export default {
  components: {
    Preview,
    SimpleGallery,
  },
  data() {
    return {
      items: [],
      imageList: [],
    };
  },
  created() {
    let url = `/member/b9d2bfc7-8891-4241-0700-08db957c6f6e/MemberImages`;
    this.$http.get(url).then((res) => {
      this.imageList = res.items;
    });
  },
  methods: {
    // Function to handle the file input change event
    handleFileSelect(evt) {
      Array.from(evt.target.files).forEach((file) => {
        this.items.push({
          uid: getUID(),
          file: file,
        });
      });
    },
    clickDelete(uid) {
      let index = this.items.findIndex((item) => item.uid === uid);
      this.items.splice(index, 1);
    },
  },
};
</script>
  
  <style>
</style>